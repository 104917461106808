<template>
  <div>
    <div class="d-flex justify-space-between">
      <div class="d-flex" style="gap: 20px">
        <v-text-field
          v-model="search"
          clearable
          outlined
          dense
          height="40px"
          label="Search Service"
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>
      </div>
      <v-btn
        class="text-capitalize"
        color="primary"
        height="40px"
        outlined
        @click="
          toggleAddUpdateService({ show: true, id: null, type: $keys.ADD })
        "
      >
        <v-icon left>mdi-plus</v-icon>Add New Service
      </v-btn>
    </div>

    <v-data-table
      class="elevation-3"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>

      <template v-slot:[`item.service_cost`]="{ item }">
        <div class="d-flex align-center justify-center">
          ₹ {{ item.cost }}
          <div style="max-width: 100px">
            <v-edit-dialog
              @save="onSave(item.id, 'cost')"
              @open="editDialog.cost = item.cost"
              @close="editDialog.cost = 0"
              large
              transition="slide-y-transition"
            >
              <v-btn icon x-small color="primary" class="ml-1">
                <v-icon x-small>mdi-pencil-outline</v-icon>
              </v-btn>
              <template v-slot:input>
                <div class="my-4">Service Cost</div>
                <v-text-field
                  v-model="editDialog.cost"
                  label="Enter Cost"
                  height="42px"
                  type="number"
                  outlined
                  dense
                  @keydown="$rules.blockInvalidChar"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </div>
        </div>
      </template>

      <template v-slot:[`item.tat`]="{ item }">
        <div>Client TAT: {{ item.client_tat_days }} days</div>
        <div>Internal TAT: {{ item.internal_tat_days }} days</div>
        <v-edit-dialog
          @save="onSave(item.id, 'tat')"
          @open="onChangeTatDialogOpen(item)"
          @close="onChangeTatDialogClose"
          large
          transition="slide-y-transition"
        >
          <v-btn
            x-small
            rounded
            text
            color="primary"
            class="mt-1 text-capitalize"
          >
            <v-icon x-small class="pr-1">mdi-pencil-outline</v-icon>
            Change TAT
          </v-btn>
          <template v-slot:input>
            <div class="my-4">Default TAT</div>
            <div class="mt-6">
              <v-text-field
                v-model="editDialog.client_tat"
                label="Client TAT Days"
                height="42px"
                type="number"
                outlined
                dense
                hide-spin-buttons
                @keydown="$rules.blockInvalidChar"
              ></v-text-field>
              <v-text-field
                v-model="editDialog.internal_tat"
                label="Internal TAT Days"
                height="42px"
                type="number"
                outlined
                dense
                hide-spin-buttons
                @keydown="$rules.blockInvalidChar"
              ></v-text-field>
            </div>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.required_documents="{ item }">
        <div v-if="item.is_document_required">
          <div v-if="item.required_documents.length">
            <div v-for="document in item.required_documents" :key="document.id">
              {{ document.title }}
            </div>
          </div>

          <v-edit-dialog
            @save="onSave(item.id, 'required_documents')"
            @open="onAddDocumentDialogOpen(item)"
            @close="editDialog.required_documents = []"
            large
            transition="slide-y-transition"
          >
            <v-btn
              x-small
              rounded
              text
              color="primary"
              class="mt-1 text-capitalize"
            >
              <v-icon x-small class="pr-1">mdi-plus</v-icon>
              Add Document
            </v-btn>
            <template v-slot:input>
              <div style="width: 250px">
                <div class="my-4">Required Documents</div>
                <div class="my-4">
                  <v-checkbox
                    v-for="document in documentList"
                    :key="document.id"
                    v-model="editDialog.required_documents"
                    :label="document.title"
                    :value="document.id"
                    multiple
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
            </template>
          </v-edit-dialog>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.executives`]="{ item }">
        <div v-if="item.executives.length">
          <div v-for="executive in item.executives" :key="executive.id">
            {{ executive.name }}
          </div>
        </div>
        <v-edit-dialog
          large
          transition="slide-y-transition"
          @save="onSave(item.id, 'executives')"
          @open="onAddExecutiveDialogOpen(item)"
          @close="onAddExecutiveDialogClose"
        >
          <v-btn
            x-small
            rounded
            text
            color="primary"
            class="mt-1 text-capitalize"
          >
            <v-icon x-small class="pr-1">mdi-plus</v-icon>
            Add Executive
          </v-btn>
          <template v-slot:input style="max-width: 100px">
            <div class="my-4">Add Executive</div>
            <v-autocomplete
              :items="executiveList"
              v-model="editDialog.executives"
              item-text="name"
              item-value="id"
              outlined
              chips
              deletable-chips
              small-chips
              multiple
              style="max-width: 400px"
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.vendors`]="{ item }">
        <div v-if="item.is_vendor_required">
          <div v-if="item.vendors.length">
            <div
              v-for="service_vendor in item.vendors"
              :key="service_vendor.id"
              class="d-flex justify-center align-center"
              style="gap: 2px"
            >
              <div>
                {{ service_vendor.vendor.name }}
                <span class="text-caption">
                  [Cost: ₹{{ service_vendor.cost }}]
                </span>
              </div>
              <v-edit-dialog
                large
                transition="slide-y-transition"
                @save="onSave(service_vendor.id, 'edit_vendor')"
                @open="onEditVendorDialogOpen(service_vendor)"
                @close="onVendorDialogClose"
              >
                <v-btn x-small icon text>
                  <v-icon x-small>mdi-pencil-outline</v-icon>
                </v-btn>
                <template v-slot:input style="max-width: 100px">
                  <div class="my-4">Update Vendor</div>
                  <div class="mt-4">
                    <v-text-field
                      v-model="editDialog.vendor.cost"
                      label="Service Cost"
                      height="42px"
                      type="number"
                      outlined
                      dense
                      hide-spin-buttons
                      @keydown="$rules.blockInvalidChar"
                    ></v-text-field>
                  </div>
                </template>
              </v-edit-dialog>
              <v-btn
                x-small
                icon
                text
                @click="deleteServiceVendor(service_vendor.id)"
              >
                <v-icon x-small>mdi-delete-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <v-edit-dialog
            large
            transition="slide-y-transition"
            @save="onSave(item.id, 'add_vendor')"
            @close="onVendorDialogClose"
          >
            <v-btn
              x-small
              rounded
              text
              color="primary"
              class="mt-1 text-capitalize"
            >
              <v-icon x-small>mdi-plus</v-icon>
              Add Vendor
            </v-btn>
            <template v-slot:input style="max-width: 100px">
              <div class="my-4">Add Vendor</div>
              <div class="mt-4">
                <v-autocomplete
                  label="Select Vendor"
                  :items="vendorList"
                  v-model="editDialog.vendor.id"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  style="max-width: 400px"
                ></v-autocomplete>
                <v-text-field
                  v-model="editDialog.vendor.cost"
                  label="Service Cost"
                  height="42px"
                  type="number"
                  outlined
                  dense
                  hide-spin-buttons
                  @keydown="$rules.blockInvalidChar"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            color="success"
            v-model="item.is_active"
            @change="changeStatus(item.is_active, item.id)"
            :label="item.is_active ? 'Active' : 'In-active'"
          ></v-switch>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            class="text-capitalize"
            color="primary"
            height="32px"
            @click="
              toggleAddUpdateService({
                show: true,
                type: $keys.EDIT,
                id: item.id,
              })
            "
          >
            edit
          </v-btn>
          <v-btn
            small
            outlined
            class="text-capitalize"
            color="primary"
            height="32px"
            @click="openCnfDialog(item, $keys.DELETE)"
          >
            delete
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>

    <AddUpdateServiceDialog @submitted="getServiceList" />
    <ConfirmationDialog @confirmed="deleteService" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
  </div>
</template>
<script>
import _ from "lodash";
import { mapActions } from "vuex";

import AddUpdateServiceDialog from "@/views/app/admin/serviceManagement/addUpdateService";
import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";

export default {
  components: { ConfirmationDialog, AddUpdateServiceDialog },
  data() {
    return {
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],
      page_total: 1,
      page_number: 1,
      page_count: 10,
      total_count: 0,

      loading: false,
      items: [],
      headers: [
        {
          sortable: false,
          text: "#",
          value: "index",
        },
        {
          sortable: false,
          text: "Service Name",
          value: "name",
        },
        {
          sortable: false,
          text: "Service Type",
          value: "type_display",
        },
        {
          sortable: false,
          text: "Service Cost",
          value: "service_cost",
        },
        {
          sortable: false,
          text: "Default TAT",
          value: "tat",
          width: 162,
        },
        {
          sortable: false,
          text: "Required Documents",
          value: "required_documents",
        },
        {
          sortable: false,
          text: "Executives",
          value: "executives",
        },
        {
          sortable: false,
          text: "Vendors",
          value: "vendors",
          width: 220,
        },
        {
          sortable: false,
          text: "Status",
          value: "status",
        },
        {
          sortable: false,
          text: "Action",
          value: "action",
        },
      ],

      editDialog: {
        cost: 0,
        client_tat: 0,
        internal_tat: 0,
        required_documents: [],
        executives: [],
        vendor: {
          id: null,
          cost: 0,
        },
      },
      documentList: [],
      executiveList: [],
      vendorList: [],
      feIrList: [],

      search: "",
      selectedItem: null,
    };
  },
  computed: {
    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },
  watch: {
    search() {
      this.debouncedReloadData();
    },

    page_count() {
      this.getServiceList();
    },

    page_number() {
      this.getServiceList();
      this.updateRoute();
    },
  },

  created() {
    const query = this.$route.query;

    if (query.page) {
      this.page_number = Number(query.page);
    }

    if (query.search) {
      this.search = query.search;
    }

    this.updateRoute();
  },

  mounted() {
    this.getServiceList();
    this.getDocumentList();
    this.getExecutiveList();
    this.getFeIrList();
    this.getVendorList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
      toggleCnfDialog: "dialogs/toggleConfirmationDialog",
      setCnfDialogBtnLoading: "dialogs/setConfirmationDialogBtnLoading",
      toggleAddUpdateService: "admin/toggleAddUpdateService",
    }),

    navClickHandler(navKey) {
      if (navKey === "service_management") {
        this.getServiceList();
        this.updateRoute();
      }
    },

    debouncedReloadData: _.debounce(function () {
      this.getServiceList();
      this.updateRoute();
    }, 1000),

    updateRoute() {
      const query = {};

      if (this.search) {
        query.search = String(this.search);
      }

      if (this.page_number) {
        query.page = Number(this.page_number);
      }

      this.$router
        .replace({
          path: this.$route.path,
          query: query,
        })
        .catch((error) => error);
    },

    getDocumentList() {
      this.documentList = [];

      const onSuccess = (res) => {
        this.documentList = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.document.list, {
        params,
        onSuccess,
      });
    },

    getExecutiveList() {
      const onSuccess = (res) => {
        this.executiveList = res.data.data;
      };

      const params = {
        role: this.$keys.OPERATIONS_EXECUTIVE,
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
      });
    },

    getFeIrList() {
      const onSuccess = (res) => {
        this.feIrList = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.fe_ir.list, {
        params,
        onSuccess,
      });
    },

    getVendorList() {
      const onSuccess = (res) => {
        this.vendorList = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.vendor.list, {
        params,
        onSuccess,
      });
    },

    getServiceList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.page_total = res.data.page_info.page_total;
        this.page_number = res.data.page_info.page_number;
        this.total_count = res.data.page_info.total_count;
        this.items = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
        search: this.search,
      };

      return this.$request(this.$keys.GET, this.$urls.master.service.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    openCnfDialog(item, action) {
      this.toggleCnfDialog({ show: true, action: action });
      this.selectedItem = item.id;
    },

    changeStatus(value, id) {
      const data = {
        is_active: value,
      };

      const params = {
        service_id: id,
      };

      return this.$request(this.$keys.PATCH, this.$urls.master.service.update, {
        params,
        data,
      });
    },

    deleteService() {
      const onSuccess = (res) => {
        this.toggleCnfDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getServiceList();
      };

      const onFinally = () => {
        this.setCnfDialogBtnLoading({ loading: false });
      };

      const params = {
        service_id: this.selectedItem,
      };

      return this.$request(
        this.$keys.DELETE,
        this.$urls.master.service.delete,
        {
          params,
          onSuccess,
          onFinally,
        }
      );
    },

    onAddDocumentDialogOpen(item) {
      item.required_documents.forEach((document) => {
        this.editDialog.required_documents.push(document.id);
      });
    },

    onAddExecutiveDialogOpen(item) {
      item.executives.forEach((document) => {
        this.editDialog.executives.push(document.id);
      });
    },

    onAddExecutiveDialogClose() {
      this.editDialog.executives = [];
    },

    onEditVendorDialogOpen(item) {
      this.editDialog.vendor = {
        id: item.vendor.id,
        cost: item.cost,
      };
    },

    onVendorDialogClose() {
      this.editDialog.vendor = {
        id: null,
        cost: 0,
      };
    },

    onChangeTatDialogOpen(item) {
      this.editDialog.client_tat = item.client_tat_days;
      this.editDialog.internal_tat = item.internal_tat_days;
    },

    onChangeTatDialogClose() {
      this.editDialog.client_tat = 0;
      this.editDialog.internal_tat = 0;
    },

    onEdit(params, data) {
      const onSuccess = () => {
        this.getServiceList();
      };

      return this.$request(this.$keys.PATCH, this.$urls.master.service.update, {
        params,
        data,
        onSuccess,
      });
    },

    onSave(id, field) {
      const formData = {};
      const param = {
        service_id: id,
      };

      switch (field) {
        case "cost": {
          formData["cost"] = this.editDialog.cost;
          this.onEdit(param, formData);
          break;
        }
        case "tat": {
          formData["client_tat_days"] = this.editDialog.client_tat;
          formData["internal_tat_days"] = this.editDialog.internal_tat;
          this.onEdit(param, formData);
          break;
        }
        case "required_documents": {
          formData["required_documents"] = this.editDialog.required_documents;
          this.onEdit(param, formData);
          break;
        }
        case "executives": {
          formData["executives"] = this.editDialog.executives;
          this.onEdit(param, formData);
          break;
        }
        case "add_vendor": {
          this.addServiceVendor(id);
          break;
        }
        case "edit_vendor": {
          this.editServiceVendor(id);
          break;
        }
        default:
          break;
      }
    },

    addServiceVendor(service_id) {
      const onSuccess = () => {
        this.getServiceList();
      };

      const data = {
        service: service_id,
        vendor: this.editDialog.vendor.id,
        cost: this.editDialog.vendor.cost,
      };

      return this.$request(
        this.$keys.POST,
        this.$urls.master.service.vendor.create,
        {
          data,
          onSuccess,
        }
      );
    },

    editServiceVendor(service_vendor_id) {
      const onSuccess = () => {
        this.getServiceList();
      };

      const params = {
        id: service_vendor_id,
      };

      const data = {
        vendor: this.editDialog.vendor.id,
        cost: this.editDialog.vendor.cost,
      };

      return this.$request(
        this.$keys.PATCH,
        this.$urls.master.service.vendor.update,
        {
          data,
          params,
          onSuccess,
        }
      );
    },

    deleteServiceVendor(service_vendor_id) {
      const onSuccess = () => {
        this.getServiceList();
      };

      const params = {
        id: service_vendor_id,
      };

      return this.$request(
        this.$keys.DELETE,
        this.$urls.master.service.vendor.delete,
        {
          params,
          onSuccess,
        }
      );
    },
  },
};
</script>
